import React from "react";
import { motion } from "framer-motion";
import {
  Container,
  Typography,
  Grid,
  Box,
  Paper,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  TextField,
  Button,
} from '@mui/material';
import {
  StyledSection,
  SectionTitle,
  itemVariant,
  containerVariant,
} from "../styles/components";
import yellowGrey from "../images/yellow-grey.jpg";
import { AppContent } from "../data/appConstants";

function ContactUs() {
  return (
    <StyledSection
      id="contact-us"
      //   ref={contactUsRef}
      style={{
        backgroundImage: `url(${yellowGrey})`,
      }}
    >
      {/* <SectionTitle>Contact Us</SectionTitle> */}
      {/* <Container maxWidth="lg"> */}
      <Card style={{ width: "50vw" }}>
        <CardActionArea>
          <CardContent>
            <Typography variant="h6" align="center">
              Contact Us{" "}
            </Typography>

            <form>
              {AppContent.contact_us.form_items.map((item) => (
                <Box m={3}>
                  <TextField
                    id={item.formId}
                    placeholder={item.text}
                    variant="outlined"
                    style={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "20px",
                    }}
                  />
                </Box>
              ))}
            </form>
          </CardContent>
        </CardActionArea>
        <CardActions style={{ display: "flex" }}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            style={{ margin: "1rem auto" }}
          >
            Send
          </Button>
        </CardActions>
      </Card>
      {/* </Container> */}
    </StyledSection>
  );
}

export default ContactUs;
