import { Stack, Typography, Container } from "@mui/material";
import { textAlign } from "@mui/system";
import React from "react";

function PrivacyPolicy() {
  return (
    <Container fixed>
      <Stack>
        <h1>Privacy Policy</h1>
        <h2>YOUR PERSONAL DATA AND HOW WE USE IT</h2>
        <p>
          we collect, use and store your personal data to ensure we can provide
          various services to you and offer you a personal experience online and
          in our stores. this may occur on our Site or via other means. we will
          keep your personal data confidential at all times and only collect,
          use and store in accordance with this Privacy Policy.
        </p>

        <h2>COLLECTION OF YOUR PERSONAL DATA</h2>
        <p>
          we collect the different levels of your personal data, both on our
          site and at our physical stores:
        </p>

        <p>
          you will be browsing our site as a guest if you are not a registered
          user. we will not collect or use any personal data from you, but you
          may not be able to use all the services and features that we offer to
          registered users.
        </p>
        <p>
          you will need to input certain of your personal data when registering
          with us on our site (such as your name and contact details), in order
          to receive various features and services that we offer to our
          registered users, such as email updates, responses to your queries,
          and priority for our limited edition items.
        </p>
        <p>
          you can only place an order with us as a registered user on our site.
          in both cases, we will only collect information necessary to fullfill
          the relevant order (such as your name, contact details and
          payment/credit card information).
        </p>
        <p>
          you may wish to verify or update any of your personal data that we
          hold. you may do so by contacting our Customer Service, or amending
          your customer profile in your registered account.
        </p>

        <h2>USE OF YOUR PERSONAL DATA</h2>
        <p>
          we use the personal data you provide to us for the following purposes:
          <ul>
            <li>
              to provide our services to you. This includes responding any of
              your enquiries, providing you with recommendations on products on
              our Site, maintaining your shopping cart on our site, assessing
              your applications with us for any of our services, and fulfilling
              orders you may make with us (including the security checks of your
              payments and verification).
            </li>
            <li>
              to provide you with marketing communications, as we may
              occasionally update you our latest products, news via e-mail, post
              and telephone.
            </li>
          </ul>
        </p>

        <h2>COOKIES</h2>
        <p>
          please note that we may automatically collect data from you relating
          to your online session to provide our services to you. our site may
          use cookies, which is a small information file that is sent to your
          computer or other device when you visit our site and it will
          recoganize your device on future visits, for example, remembering your
          items in shopping cart. If you do not want cookies to be stored on
          your computer, you can change the settings on your browser. The “help”
          section on your browser will provide details on how to manage the
          cookie settings.
        </p>

        <h2>MARKETING COMMUNICATIONS</h2>
        <p>
          you agree that we may use your personal data to offer you marketing
          communications from time to time. we may provide you our goods and
          services, and the goods and services of our third party providers.
          this may be presented in various forms, including newsletters,
          advertisements and special promotional events, and delivered by
          various methods based on the personal information you provide to us,
          such as by email, phone notifications or postal mail.
        </p>
        <p>
          you have the options to opt-out of receiving marketing communications
          from us. if you do not wish to continue to receive it, you may click
          on the “unsubscribe” link in any email communications which we might
          send you, or contact our Customer Service so that we could amend your
          personal data for you.
        </p>

        <h2>PROTECTING YOUR SECURITY</h2>
        <p>
          we do our best to protect the personal data that you provide to us.
          please be aware that despite our efforts, no data security measures
          can guarantee 100% security at all times.
        </p>
        <p>
          when you make online purchase, we protect the personal data that you
          provide to us. we will encrypt any personal data you transmit to us
          via our site using Secure Socket Layer (SSL) – an industry standard
          for encryption over the Internet.
        </p>
        <p>
          we may use your personal information in order to conduct appropriate
          anti-fraud checks. this may be disclosed to a credit reference agency,
          which may keep a record of the information. this is only for
          confirming your identity, a credit check is not performed and your
          credit rating is unaffected.
        </p>
        <p>
          Nothing in this Privacy Policy shall limit any of your rights to the
          extent such rights cannot be limited by law.
        </p>

        <h2>DISCLOSURES OF YOUR INFORMATION</h2>
        <p>
          your personal data will be kept confidential with us, except the
          following parties that we may disclose your personal data to them:
          <ul>
            <li>
              any financial institutions or credit card issuing companies, or
              collection agencies where necessary to establish and support the
              payment being made by you
            </li>
            <li>
              any parties where such disclosure is mandated by statutory or
              contractual obligations or as required by law
            </li>
          </ul>
        </p>
        <p>
          this Privacy Policy is not applicable to any websites maintained by
          third parties. please read such third party’s privacy policy before
          providing your personal data to such third parties (whether via our
          Site or not). we are not responsible and liable for any use or breach
          of your personal data by such third parties.
        </p>

        <h2>HOW TO CHECK AND UPDATE YOUR DETAILS</h2>
        <p>
          if you wish to verify the personal data you provide to us, you may do
          so by logging in to your account to update your personal data, or
          contacting our Customer Service by Contact Us. when you contact us,
          our security procedures mean that we may request proof of identity
          before we reveal information. this proof will take the form of your
          email address and password submitted upon registration. You must keep
          this information safe, as you will be responsible for any action,
          which we will take in response to a request from someone using your
          email and password. we strongly recommend that you do not use the
          browser’s password memory function, as that would permit others using
          your terminal to access your personal information.
        </p>
      </Stack>
    </Container>
  );
}

export default PrivacyPolicy;
