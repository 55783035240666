import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
  font-family: "Raleway";
  font-weight: 500;
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
  font-family: "Raleway";
  font-weight: 500;
`;

export const StyledSection = styled.section`
  width: 100vw;
  /* height: calc(100vh - 120px); */
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
  overflow: hidden;
`;

export const Trapezoid = styled.div`
  position: "abolute";
  right: 0;

  height: 100vh;
  width: 60vw;
  background-color: #ffc000;
  transform: skew(-20deg) translate(80%, 0%);
`;

export const SectionTitle = styled.h1`
  position: absolute;
  margin: 1rem auto;
  width: 100vw;
  text-align: center;
`;

export const containerVariant = {
  hidden: { width: "50vw", height: 0, opacity: 0 },
  show: {
    width: "50vw",
    height: "30vh",
    opacity: 0.95,
    transition: { duration: 0.3, staggerChildren: 0.5, delayChildren: 0.3 },
  },
};

export const itemVariant = {
  // hidden: { opacity: 0, x: 100 },
  // show: { opacity: 1, x: 0, transition: { duration: 2 } },
  hidden: {
    opacity: 0,
    // perspective: 500,
    transform:
      "perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg)",
  },
  show: {
    opacity: 1,
    perspective: 500,
    transform: "perspective(500px) translate3d(0,0,0)",
  },
};
