import React from "react";
import { NavLink } from "react-router-dom";
import { Grid } from "@mui/material";
import { StyledLink, StyledNavLink } from "../styles/components";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav style={{ position: "sticky", height: 120 }}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={3}>
          <div>
            <StyledLink to="/">
              <img
                src={logo}
                alt="logo"
                style={{ margin: "1rem", width: "30vw", maxWidth: "80px" }}
              />
            </StyledLink>
          </div>
        </Grid>
        <Grid item xs={9}>
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              justifyContent: "space-around",
              fontSize: "1.6rem",
            }}
          >
            <li>
              <StyledLink to="/about-us">About Us</StyledLink>
            </li>
            <li>
              <StyledLink to="/mission">Mission</StyledLink>
            </li>
            <li>
              <StyledLink to="/what-we-offer">What We Offer</StyledLink>
            </li>
            <li>
              <StyledLink to="/contact-us">Contact Us</StyledLink>
            </li>
          </ul>
        </Grid>
      </Grid>
    </nav>
  );
}

export default Navbar;
