import {
  Routes,
  Route,
} from "react-router-dom";

// components
import PrivacyPolicy from "./components/PrivacyPolicy";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Mission from "./components/Mission";
import WhatWeOffer from "./components/WhatWeOffer";
import ContactUs from "./components/ContactUs";

function App() {
  // const { ref: homeRef, inView: homeInView } = useInView();
  // const { ref: aboutUsRef, inView: aboutUsInView } = useInView();
  // const { ref: missionRef, inView: missionInView } = useInView();
  // const { ref: whatWeOfferRef, inView: whatWeOfferInView } = useInView();
  // const { ref: contactUsRef, inView: contactUsInView } = useInView();

  // useEffect(() => {
  //   console.log(aboutUsInView);
  // }, [aboutUsInView]);

  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/what-we-offer" element={<WhatWeOffer />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default App;
