import tablePlants from "../images/table-plants.jpg";
import React from "react";
import { motion } from "framer-motion";
import {
  Container,
  Typography,
  Grid,
  Box,
  Paper,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  TextField,
  Button,
} from '@mui/material';
import {
  StyledSection,
  SectionTitle,
  itemVariant,
  containerVariant,
} from "../styles/components";

function Mission() {
  return (
    <StyledSection
      id="mission"
      style={{
        backgroundImage: `url(${tablePlants})`,
      }}
      //   ref={missionRef}
    >
      <SectionTitle style={{ padding: "2rem" }}>Mission</SectionTitle>
      <Typography variant="h5" style={{ fontFamily: "Lato", fontSize: "2rem" }}>
        With our clients, we build websites that grows clients’ businesses.
      </Typography>
    </StyledSection>
  );
}

export default Mission;
