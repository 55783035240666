export const AppContent = {
  home: {
    content: [
      {
        id: 1,
        text:
          "We shall leverage our deep experience to create a tailored website just for you.",
      },
      {
        id: 2,
        text:
          "In this digital era, many people rely on the internet, from searching information to shopping.  What effectively connects your clients may not be your face to face conversation or a physical shop. Instead, a site on the web will do. A website that introduces and promotes your business.",
      },
    ],
  },
  about_us: {
    content: [
      {
        id: 1,
        text:
          "F2M will build websites and provide related consultation services.",
      },
      {
        id: 2,
        text:
          "We have 10+ years of experience in building websites. We have served companies from various trades and helped them achieve their targets say, revenue increase. They now do business from a new horizon.",
      },
      {
        id: 3,
        text:
          "We believe a website can upgrade your business and helps you explore more business opportunities.  We create value and that is why we are here to listen to your dream website and work hard to make it happen.",
      },
    ],
  },
  mission: {
    content: [
      {
        id: 1,
        text:
          "With our clients, we build websites that grows clients’ businesses.",
      },
    ],
  },
  what_we_offer: {
    content: [{ id: 1, text: "To build websites for the following purposes:" }],
    purposes: [
      { id: 1, text: "Marketing" },
      { id: 2, text: "Online shop" },
      { id: 3, text: "Employee management" },
      { id: 4, text: "Information" },
      { id: 5, text: "We can discuss" },
      { id: 6, text: "Business process analysis" },
    ],
  },
  contact_us: {
      content: [{id: 1, text: 'Thank you for your interest in F2M.  Please fill out the form below to ask a question.'}],
      form_items: [
          {id: 1,formId: 'type', text: 'Type of inquiry (General/ Technical Support/ Career)'},
          {id: 2,formId: 'name', text: 'Name'},
          {id: 3,formId: 'company', text: 'Company'},
          {id: 4,formId: 'email', text: 'Email'},
          {id: 5,formId: 'phone', text: 'Phone'},
          {id: 6,formId: 'subject', text: 'Subject'},
          {id: 7,formId: 'message', text: 'Message'},
          {id: 8,formId: 'source', text: 'Where did you hear F2M'},
    ]
  }
};
