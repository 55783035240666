import React from "react";
import { motion } from "framer-motion";
import {
  Container,
  Typography,
  Grid,
  Box,
  Paper,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  TextField,
  Button,
} from "@mui/material";
import whiteGeo from "../images/white-geometry.jpg";
import {
  StyledSection,
  itemVariant,
  containerVariant,
} from "../styles/components";

function Home() {
  return (
    <StyledSection
      id="home"
      style={{
        backgroundImage: `url(${whiteGeo})`,
      }}
    >
      {/* <Typography variant="h3" color="primary">
    Home
  </Typography> */}
      {/* <SectionTitle color="primary">Home</SectionTitle> */}
      <motion.div
        variants={containerVariant}
        style={{ backgroundColor: "white" }}
        initial="hidden"
        animate="show"
      >
        <Box m={3}>
          <motion.p
            variants={itemVariant}
            style={{ fontSize: "1.4rem", fontFamily: "Lato" }}
          >
            We shall leverage our deep experience to create a tailored website
            just for you.
          </motion.p>
        </Box>{" "}
        <Box m={3}>
          <motion.p
            variants={itemVariant}
            style={{ fontSize: "1.4rem", fontFamily: "Lato" }}
          >
            In this digital era, many people rely on the internet, from
            searching information to shopping.
          </motion.p>
        </Box>{" "}
        <Box m={3}>
          <motion.p
            variants={itemVariant}
            style={{ fontSize: "1.4rem", fontFamily: "Lato" }}
          >
            What effectively connects your clients may not be your face to face
            conversation or a physical shop.
          </motion.p>
        </Box>{" "}
        <Box m={3}>
          <motion.p
            variants={itemVariant}
            style={{ fontSize: "1.4rem", fontFamily: "Lato" }}
          >
            Instead, a site on the web will do. A website that introduces and
            promotes your business.
          </motion.p>
        </Box>
        {/* {AppContent.home.content.map((content) => (
      <motion.p
        variants={itemVariant}
        style={{ fontSize: "1.2rem", fontFamily: "Lato" }}
        initial="hidden"
        animate="show"
      >
        {content.text}
      </motion.p>
    ))} */}
      </motion.div>
    </StyledSection>
  );
}

export default Home;
