import React from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  Paper,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  TextField,
  Button,Stack
} from "@mui/material";
import { StyledSection, SectionTitle, Trapezoid } from "../styles/components";

function AboutUs() {
  return (
    <StyledSection
      id="about-us"
      // ref={aboutUsRef}
      style={{ overflow: "hidden" }}
    >
      <Trapezoid></Trapezoid>
      <SectionTitle color="primary">About Us</SectionTitle>
      <Grid container spacing={2} style={{ position: "absolute" }}>
        <Grid container item xs={7}>
          <Stack>
            <Grid item>
              <Box m={3}>
                <Typography variant="h6" style={{ fontFamily: "Lato" }}>
                  F2M will build websites and provide related consultation
                  services.
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box m={3}>
                <Typography variant="h6" style={{ fontFamily: "Lato" }}>
                  We have 10+ years of experience in building websites. We have
                  served companies from various trades and helped them achieve
                  their targets say, revenue increase. They now do business from
                  a new horizon.
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box m={3}>
                <Typography variant="h6" style={{ fontFamily: "Lato" }}>
                  We believe a website can upgrade your business and helps you
                  explore more business opportunities. We create value and that
                  is why we are here to listen to your dream website and work
                  hard to make it happen.
                </Typography>
              </Box>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          {/* <img
        src={bulb}
        style={{ width: "20vw", position: 'absolute', right: 100, bottom: 0, opacity: 0.8}}
      /> */}
        </Grid>
      </Grid>
    </StyledSection>
  );
}

export default AboutUs;
