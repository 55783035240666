import React from "react";
import { motion } from "framer-motion";
import {
  Container,
  Typography,
  Grid,
  Box,
  Paper,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  TextField,
  Button,Stack
} from "@mui/material";
import whiteGeo from "../images/white-geometry.jpg";
import {
  StyledSection,
  SectionTitle,
  itemVariant,
  containerVariant,
} from "../styles/components";
import { AppContent } from "../data/appConstants";
import imac from "../images/imac.jpg";

function WhatWeOffer() {
  return (
    <StyledSection
      id="what-we-offer"
      // ref={whatWeOfferRef}
    >
      <SectionTitle style={{ padding: "2rem" }}>What We Offer</SectionTitle>
      <Grid container spacing={2}>
        <Grid container item xs={6}>
          <Stack
            style={{ height: "80vh" }}
          >
            <img
              alt="imac"
              src={imac}
              style={{
                width: "100%",
                objectFit: "contain",
                margin: "2rem",
                padding: "1rem",
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography
              variant="h5"
              style={{ alignSelf: "flex-end", margin: "2rem" }}
            >
              To build websites for the following purposes:
            </Typography>
            {AppContent.what_we_offer.purposes.map((purpose) => (
              <Grid item>
                <Box m={3}>
                  <Typography variant="h6" style={{ fontFamily: "Lato" }}>
                    {purpose.text}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </StyledSection>
  );
}

export default WhatWeOffer;
